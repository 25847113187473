<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <div class="d-flex justify-space-around pb-3">
      <div v-for="template in templates" :key="template.key">
        <v-btn
          elevation="0"
          @click="selectTemplate(template)"
          :text="selectedTemplate != template.key"
          color="primary"
          >{{ template.name }}</v-btn
        >
      </div>
    </div>
    <Shop
      v-if="selectedTemplate == 'shop'"
      :self="self"
      :role="role"
      :model="model"
      :url="url"
      action="edit"
      :callbackSuccess="callbackSuccess"
    >
    </Shop>
    <Theme
      v-if="selectedTemplate == 'theme'"
      :self="self"
      :role="role"
      :model="model"
      :url="url"
      action="edit"
      :callbackSuccess="callbackSuccess"
    >
    </Theme>
    <Event
      v-if="selectedTemplate == 'event'"
      :self="self"
      :role="role"
      :model="model"
      :url="url"
      action="edit"
      :callbackSuccess="callbackSuccess"
    >
    </Event>
    <Feature
      v-if="selectedTemplate == 'feature'"
      :self="self"
      :role="role"
      :model="model"
      :url="url"
      action="edit"
      :callbackSuccess="callbackSuccess"
    >
    </Feature>
    <Book
      v-if="selectedTemplate == 'book'"
      :self="self"
      :role="role"
      :model="model"
      :url="url"
      action="edit"
      :callbackSuccess="callbackSuccess"
    >
  </Book>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Shop from "./Shop.vue";
import Event from "./Event.vue";
import Feature from "./Feature.vue";
import Book from "./Book.vue";
import Theme from "./Theme.vue";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: { Shop, Event, Feature, Book, Theme },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    templates: [
      {
        name: "New Clothing Set",
        key: "shop",
      },
      {
        name: "New Theme",
        key: "theme",
      },
      {
        name: "New Book",
        key: "book",
      },
      {
        name: "New Event",
        key: "event",
      },
      {
        name: "New Feature",
        key: "feature",
      },
    ],
    selectedTemplate: "shop",
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    entertName(name) {
      this.form.name = name;
    },
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    selectTime(time, key) {
      if (key == "timeStart") {
        this.form.timeStart = time;
      } else {
        this.form.timeEnd = time;
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.form.timestampStart = moment(
          this.form.dateStart + " " + this.form.timeStart
        ).toISOString();
        this.form.timestampEnd = moment(
          this.form.dateEnd + " " + this.form.timeEnd
        ).toISOString();
        if (this.form.timestampStart > this.form.timestampEnd) {
          this.api.callbackError(
            "The news start date and time should be less than the news end date and time."
          );
        } else {
          this.submit();
        }
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    selectTemplate(template) {
      this.selectedTemplate = template.key;
    },
    //EOC
  },
  mounted(){
    this.selectedTemplate= this.self.templateKey;
  }
};
</script>